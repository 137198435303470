import TheCheckboxRadio from './checkbox-radio/index.vue'
import TheTextField from './text-field/index.vue'

export default {
  name: 'answer-templates',
  props: {
    payload: {
      type: Object
    },
    items: {
      type: Array
    }
  },
  components: {
    TheCheckboxRadio,
    TheTextField
  }
}
