import { mapActions, mapGetters, mapMutations } from 'vuex'
import { camelCase, debounce, snakeCase } from 'lodash'
import moment from 'moment'

import mixins from '@/mixins'
import validationMixin from '@/mixins/validation'

import MainEditor from '@/components/editor/index.vue'
import ImageFile from '@/components/image-file/index.vue'
import SlugNameInput from '@/components/slug-name-input/index.vue'
import SelectPackages from '@/components/forms/SelectPackages'

import SelectUserPosition from '@/components/select-user-position';

export default {
  name: 'courses-contents-studying-lesson-general',
  mixin: [mixins, validationMixin],
  data () {
    return {
      valid: false,
      openDatepicker: false,
      search: null,
      searchInput: null,
      payload: {
        name: '',
        slug: '',
        description: '' || '<p></p>',
        statusId: '',
        packages: '',
        // sort: '',
        openingAt: '',
        duration: '',
        openingDay: 0,
        speakers: [],
        live: false
      }
    }
  },
  components: {
    SlugNameInput,
    MainEditor,
    ImageFile,
    SelectPackages,
    SelectUserPosition
  },
  computed: {
    ...mapGetters({
      courseInfo: 'coursesInfo/info',
      packagesList: 'coursesPackages/list',
      setting: 'courses/setting',
      editId: 'lessons/editId',
      currentItem: 'lessons/currentItem',
      lessonLoading: 'lessons/lessonLoading',
      updateImageLoading: 'lessons/updateImageLoading',
      speakersLoading: 'user/speakersLoading',
      speakers: 'user/speakers'
    }),
    computedDateFormatted () {
      return moment(this.payload.openingAt).format('YYYY-MM-DD HH:mm:ss')
    }
  },
  created () {
    if (this.editId) {
      if (!this.currentItem) {
        // eslint-disable-next-line camelcase
        const { course_id, module_id } = this.$route.params
        this.fetchData({
          course_id: course_id,
          module_id: module_id,
          id: this.editId
        }).then(() => {
          this.setData()
          this.fetchSpeakers({
            isAuthors: false,
          })
        })
      } else {
        this.setData()
      }
    } else {
      this.fetchSpeakers({
        isAuthors: false,
      })
    }

    this.$on('event-save', () => {
      this.submit()
    })
  },
  methods: {
    ...mapActions({
      fetchData: 'lessons/GET_ITEM',
      create: 'lessons/CREATE',
      update: 'lessons/UPDATE',
      fetchSpeakers: 'user/GET_SPEAKERS',
      updateImage: 'lessons/LESSON_UPDATE_IMAGE'
    }),
    ...mapMutations({
      changeId: 'lessons/CHANGE_EDIT_ID'
    }),
	  onReloadUserPosition (data) {
		  this.fetchSpeakers({
			  isAuthors: false,
			  id: this.payload.speakers.join(','),
        type: 'all_ids'
		  })
      this.payload.speakers.push({
        active: data.active,
        activity: data.activity,
        avatar: data.avatar,
        country_id: data.country_id,
        created_at: data.created_at,
        full_name: data.full_name,
        id: data.id,
        position: data.position,
        position_id: data.position_id,
        updated_at: data.updated_at,
        user_id: data.user_id
      })
      this.submit()
    },

    updateImageLocal (params) {
      // eslint-disable-next-line camelcase
      const { course_id, module_id } = this.$route.params
      params.append('course_id', course_id)
      params.append('module_id', module_id)
      params.append('lesson_id', this.currentItem.id)
      this.updateImage(params).then(() => {
        this.$toasted.success(this.$t('success_updated'))
      })
    },
    setData () {
      const transformObj = {}
      const item = this.currentItem
      Object.keys(item).forEach(e => {
        transformObj[camelCase(e)] = item[e]
      })
      Object.assign(this.payload, transformObj)
      this.payload.packages = item.packages.data.map(e => e.id)
      this.payload.speakers = this.currentItem.speakers.data.map(e => e.details.data)
      this.payload.speakers.map(e => !this.speakers.find(item => item.id === e.id) && this.speakers.push(e))
    },
    closeDialog () {
      this.$emit('close-dialog')
    },
    createRequestPayload () {
      const data = Object.assign(this.payload)
      const formData = {}
      Object.keys(data).forEach(e => {
        formData[snakeCase(e)] = data[e]
      })

      // eslint-disable-next-line camelcase
      const { course_id, module_id } = this.$route.params
      formData.course_id = course_id
      formData.module_id = module_id
      formData.opening_at = this.computedDateFormatted
      if (this.currentItem) {
        formData.id = this.currentItem.id
      }
      formData.speakers = formData.speakers.map(el => {
		  return el.id
      });

      return formData
    },
    changeInputSpeakers: debounce(function (val) {
      if (!val) return
      if (this.payload.speakers?.full_name !== val) {
        this.fetchSpeakers({
          keywords: val,
        })
      }
    }, 500),
    autoCompleteChanged (e) {
      this.payload.speakers = e
    },
    submit () {
      this.$refs.form.validate()
      this.$refs.observer.validate()
      if (this.valid) {
        if (!this.currentItem) {
          this.create(this.createRequestPayload()).then(response => {
            this.changeId(response.id)
            this.$toasted.success(this.$t('lessonSuccessCreate'))
          })
        } else {
          this.update(this.createRequestPayload()).then(() => {
            this.$toasted.success(this.$t('success_updated'))
          })
        }
      }
    }
  }
}
