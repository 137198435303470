import { mapGetters, mapMutations } from 'vuex'

import General from '../general/index.vue'
import LessonContent from '../content/index.vue'
import Seo from '../seo/index.vue'

export default {
  name: 'courses-contents-studying-lesson-dialog-view',
  data () {
    return {
      dialog: true,
      activeTab: 'general',
    }
  },
  components: {
    General,
    LessonContent,
    Seo
  },
  computed: {
    ...mapGetters({
      currentItem: 'lessons/currentItem',
      editId: 'lessons/editId',
	  lessonLoading: 'lessons/lessonLoading',
	  updateSeoLoading: 'lessonsSeo/updateSeoLoading',
    }),
    disableTabs () {
      if (!this.editId) {
        return true
      } else {
        return !this.currentItem
      }
    },
    tabs () {
      return [
        {
          name: 'General',
          slug: 'general',
          id: 1
        },
        {
          name: 'Content',
          slug: 'content',
          id: 2,
          disabled: this.disableTabs
        },
        {
          name: 'SEO',
          slug: 'seo',
          id: 3,
          disabled: this.disableTabs
        }
      ]
    }
  },
  methods: {
    ...mapMutations({
      resetGeneral: 'lessons/SET_ITEM',
      resetContent: 'lessonsContent/SET_LIST'
    }),
    changeCloseDialog () {
      this.dialog = false
      this.$emit('close-dialog')
    },

	save(){
	  this.$refs['general'].$emit('event-save');
	}
  },
  destroyed () {
    this.resetGeneral(null)
    this.resetContent([])
  }
}
