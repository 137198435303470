import { mapActions, mapGetters, mapMutations } from 'vuex'
import { snakeCase } from 'lodash'
import moment from 'moment'

import mixins from '@/mixins'
import validationMixin from '@/mixins/validation'

import ImageFile from '@/components/image-file/index.vue'
import MainEditor from '@/components/editor/index.vue'
import SlugNameInput from '@/components/slug-name-input/index.vue'
import SelectPackages from '@/components/forms/SelectPackages'

export default {
  name: 'courses-contents-studying-home-work-general',
  mixin: [mixins, validationMixin],
  data() {
    return {
      valid: false,
      payload: {
        name: '',
        slug: '',
        typeId: '',
        description: '',
        packages: '',
        openingAt: '',
        openingDay: 0,
        optional: false,
        availableTo: '',
        repeatedly: false,
        statusId: '',
        // sort: '',
        useCustomRules: false,
        rules: ''
      }
    }
  },
  components: {
    ImageFile,
    MainEditor,
    SlugNameInput,
    SelectPackages
  },
  computed: {
    ...mapGetters({
      courseInfo: 'coursesInfo/info',
      setting: 'courses/setting',
      currentItem: 'homeWork/currentItem',
      isLoading: 'homeWork/isLoading',
      updateImageLoading: 'homeWork/updateImageLoading',
      packagesList: 'coursesPackages/list',
      practicType: 'homeWork/practicType',
    }),

    hasPracticType() {
      if (this.currentItem) {
        return this.currentItem.typeId === 2;
      } else {
        return this.payload.typeId == 2;
      }
    },
  },
  created() {
    if (this.currentItem) {
      this.fetchData(this.$route.params).then(() => {
        this.setData()
      })
    }

    this.$on('event-save', () => {
      this.submit()
    })

    if (this.currentItem) {
      this.changePracticType(this.currentItem.typeId)
    }
  },
  watch: {
    hasPracticType() {
      this.changePracticType(this.payload.typeId)
    }
  },
  methods: {
    ...mapActions({
      fetchData: 'homeWork/GET_LIST_ITEM',
      create: 'homeWork/CREATE',
      update: 'homeWork/UPDATE',
      updateImage: 'homeWork/UPDATE_IMAGE'
    }),
    ...mapMutations({
      changeListImg: 'lessons/LESSON_FRESH_IMAGE',
      closeDialog: 'homeWork/CHANGE_SHOW_HOME_WORK_DIALOG_LESSON',
      changePracticType: 'homeWork/CHANGE_PRACTIC_TYPE'
    }),

    updateImageLocal(params) {
      // eslint-disable-next-line camelcase
      const { course_id, module_id } = this.$route.params
      params.append('course_id', course_id)
      params.append('module_id', module_id)
      params.append('home_works_id', this.currentItem.id)
      this.updateImage(params).then(response => {
        this.$toasted.success(this.$t('success_updated'))

        response.onlyList = true
        response.materialId = this.currentItem.id
        this.changeListImg(response)
      })
    },
    formattedDate(date) {
      if (!date) return ''
      return moment(date).format('YYYY-MM-DD HH:mm:ss')
    },
    setData() {
      Object.assign(this.payload, this.currentItem)
      this.payload.packages = this.currentItem.packages.data.map(e => e.id)
    },
    createRequestPayload() {
      const data = Object.assign(this.payload)
      const formData = {}
      Object.keys(data).forEach(e => {
        formData[snakeCase(e)] = data[e]
      })
      // eslint-disable-next-line camelcase
      const { course_id, module_id } = this.$route.params
      formData.course_id = course_id
      formData.module_id = module_id
      formData.available_to = this.formattedDate(this.payload.availableTo)
      formData.opening_at = this.formattedDate(this.payload.openingAt)
      if (this.currentItem) {
        formData._method = 'patch'
      }
      return formData
    },
    submit() {
      this.$refs.form.validate()
      this.$refs.observer.validate()
      if (this.valid) {
        if (!this.currentItem) {
          this.create(this.createRequestPayload()).then(() => {
            this.$toasted.success(this.$t('success_created'))
            this.setData()
          })

        } else {
          this.update(this.createRequestPayload()).then(() => {
            this.$toasted.success(this.$t('success_updated'))
          })
        }
      }
    },
    onClear() {
      this.payload.availableTo = '';
      this.$refs.datePicker.resetPicker();
      this.$refs.datePicker.clearHandler();
    },
  },
}
