import { mapActions, mapGetters, mapMutations } from 'vuex'
import { debounce } from 'lodash'

import mixins from '@/mixins'

import LessonViewDialog from '../dialog-view/index.vue'
import HomeWorkViewDialog from '../../../home-works/pages/dialog-view/index.vue'
import Draggable from 'vuedraggable'

import { courseContentStudyingLessonTableHead } from '@/modules/courses/helpers/content-styding-lessons'

export default {
  name: 'courses-contents-studying-lesson-main',
  mixin: [mixins],
  data () {
    return {
      showRemoveDialog: false,
      removingItem: null,
      openDialogView: false,
      lessons: []
    }
  },
  components: {
    LessonViewDialog,
    HomeWorkViewDialog,
    Draggable
  },
  watch: {
    'list' () {
      this.lessons = this.list
    }
  },
  computed: {
    ...mapGetters({
      list: 'lessons/list',
      loading: 'lessons/listLoading',
      lessonLoading: 'lessons/lessonLoading',
      removeListItemLoading: 'userAdmins/removeListItemLoading',
      packagesList: 'coursesPackages/list',
      showHomeWorkDialog: 'homeWork/showHomeWorkDialog',
      isLoadingRemoveHomeWork: 'homeWork/isLoading'
    }),
    courseContentStudyingLessonTableHead
  },
  created () {
    if (this.list.length <= 0) {
      // eslint-disable-next-line camelcase
      const { course_id, module_id } = this.$route.params
      this.fetchList({
        course_id: course_id,
        module_id: module_id
      }).then(() => {
        this.lessons = this.list
      })
    } else {
      this.lessons = this.list
    }

    if (!this.packagesList.length) {
      this.fetchPackages({ id: this.$route.params.course_id })
    }
  },
  methods: {
    ...mapActions({
      fetchList: 'lessons/GET_LIST',
      removeLesson: 'lessons/DELETE',
      removeHomeWork: 'homeWork/DELETE',
      updateSort: 'lessons/UPDATE_SORT',
      fetchPackages: 'coursesPackages/PACKAGES_GET_LIST'
    }),
    ...mapMutations({
      changeId: 'lessons/CHANGE_EDIT_ID',
      setList: 'lessons/SET_LIST',
      setHomeWorkEditItem: 'homeWork/SET_ITEM',
      changeHomeWorkDialog: 'homeWork/CHANGE_SHOW_HOME_WORK_DIALOG_LESSON'
    }),
    openDialogHomeWork (item) {
      this.changeHomeWorkDialog(true)
      this.setHomeWorkEditItem(item)
    },
    closeDialogView () {
      setTimeout(() => {
        this.openDialogView = false
      }, 500)
    },
    changeDialogView (item = null) {
      this.changeId(item ? item.id : '')
      this.openDialogView = true
    },
    remove () {
      if (this.removingItem.type === 'lesson') {
        this.removeLesson(this.removingItem).then(() => {
          this.showRemoveDialog = false
          this.$toasted.success(this.$t('success_deleted'))
        })
      } else {
        this.removeHomeWork(this.removingItem).then(() => {
          this.showRemoveDialog = false
          this.$toasted.success(this.$t('success_deleted'))
        })
      }
    },
    changeDialogDeleteItem (item) {
      this.showRemoveDialog = true
      // eslint-disable-next-line camelcase
      const { course_id, module_id } = this.$route.params
      this.removingItem = {
        course_id: course_id,
        module_id: module_id,
        type: item.material,
        id: item.id
      }
    },
    changeSortLessons: debounce(function (e) {
      if (e.hasOwnProperty('moved')) {
        const sortsList = this.lessons.map(e => {
          return {
            id: e.id,
            type: e.material.replace('_', '')
          }
        })
        // eslint-disable-next-line camelcase
        const { course_id, module_id } = this.$route.params
        const formData = {
          course_id: course_id,
          module_id: module_id,
          materials: sortsList
        }
        this.updateSort(formData).then(() => {
          this.$toasted.success(this.$t('success_change_position'))
        })
      }
    }, 2000),

	  onChangePosition(target, index){
		  let list = this.lessons;
		  let element = list[index];

		  if (target === 'up'){
			  this.lessons.splice(index, 1);
			  this.lessons.splice(index - 1, 0, element);

		  } else if (target === 'down'){
			  this.lessons.splice(index, 1);
			  this.lessons.splice(index + 1, 0, element);
		  }

		  this.changeSortLessons({moved: true});
	  }
  },
  destroyed () {
    this.setList(null)
  }
}
