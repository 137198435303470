<template>
  <v-row>
    <v-col class="pa-0">
      <v-textarea
        style="width: 100%;"
        class="pt-0 pb-0 question-textarea"
        v-model.trim="text"
        rows="1"
        dense
        auto-grow
        :readonly="disabled"
        @input="update"
        :placeholder="`Description ${index+1}`">
        <template v-slot:prepend>
          <v-checkbox
            flat
            @change="update"
            dense
            v-model="correct"
            class="question-checkbox ma-0">
          </v-checkbox>
        </template>
        <template v-slot:append-outer>
          <v-btn
            v-if="length > 1 || disabled"
            @click.prevent="remove"
            fab
            x-small
            color="red">
            <v-icon color="white" x-small>mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-textarea>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: 'form-checkbox-item',
  props: {
    index: {},
    length: {},
    placeholder: {
      type: String,
      default: ''
    },
    disabled: {
      default: false
    },
    item: {
      type: Object
    }
  },
  data () {
    return {
      text: '',
      correct: ''
    }
  },
  created () {
    this.text = this.item?.text || ''
    this.correct = this.item?.correct || false
  },
  methods: {
    remove () {
      this.$emit('remove-item', {
        ...this.item,
        disabled: this.disabled
      })
    },
    update () {
      const copyItem = this.item
      copyItem.text = this.text
      copyItem.correct = this.correct
      this.$emit('update-item', copyItem)
    }
  }
}
</script>
