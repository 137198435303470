import { mapActions, mapGetters } from 'vuex'
import validationMixin from '@/mixins/validation'

import AnswerTemplates from '../../components/answer-templates/index.vue'
import MainEditor from '@/components/editor/index.vue'

const snakeCaseKeys = require('snakecase-keys')
const camelCaseKeys = require('camelcase-object-deep')

export default {
    name: 'question-item',
    mixin: [validationMixin],
    props: {
        index: {
            type: Number
        },
        item: {
            type: Object
        },
        length: {
            type: Number
        }
    },
    components: {
        AnswerTemplates,
        MainEditor
    },
    data () {
        return {
            payload: {
                text        : '',
                sort        : this.index,
                typeId      : this.item.typeId,
                responseTime: '',
                points      : ''
            }
        }
    },
    watch: {
        payload: {
            handler (item) {
                this.update(item)
            },
            deep: true
        }
    },
    created () {
        Object.assign(this.payload, this.item)
    },
    computed: {
        ...mapGetters({
            currentItem : 'homeWork/currentItem',
            list        : 'homeWorkQuestions/list',
            isLoading   : 'homeWorkQuestions/isLoading'
        }),
        disabledBtn () {
            if (!this.hasPracticType){
                return !this.payload.text || !this.payload.points || !this.payload.responseTime;
            }
            
            return !this.payload.text || !this.payload.points;
        },
        hasPracticType(){
            if(!this.currentItem) return false;
            
            return this.currentItem.typeId === 2;
        }
    },
    methods: {
        ...mapActions({
            fetchQuestions      : 'homeWorkQuestions/GET_LIST',
            fetchQuestionsItem  : 'homeWorkQuestions/GET_LIST_ITEM',
            createQuestions     : 'homeWorkQuestions/CREATE',
            updateQuestion      : 'homeWorkQuestions/UPDATE'
        }),
        updateSort (sort) {
            this.payload.sort = sort
        },
        update (item) {
            this.$emit('update-item', item)
        },
        createRequestPayload () {
            const toSnakeCaseData = snakeCaseKeys(this.payload)
            const formData = new FormData()
            
            for (const i in toSnakeCaseData) {
                if (i !== 'id' && i !== 'answers') {
                    formData.append(i, toSnakeCaseData[i])
                }
            }
            
            toSnakeCaseData.answers.data.forEach((e, i) => {
                formData.append(`answers[${i}][correct]`, e.correct ? '1' : '0')
                formData.append(`answers[${i}][id]`, e.id)
                formData.append(`answers[${i}][text]`, e.text)
            })
            
            formData.append('course_id', this.$route.params.course_id)
            formData.append('module_id', this.$route.params.module_id)
            formData.append('home_work_id', this.currentItem.id)
            
            if (this.payload.hasOwnProperty('updatedAt')) {
                formData.append('id', this.payload.id)
                formData.append('_method', 'patch')
            }
            
            return formData
        },
        updateListQuestions () {
            this.fetchQuestions({
                ...this.$route.params,
                id: this.currentItem.id
            }).then(() => {
                this.$emit('update-sort')
            })
        },
        save () {
            if (this.payload.hasOwnProperty('updatedAt')) {
                this.updateQuestion(this.createRequestPayload()).then(() => {
                    this.$toasted.success(this.$t('success_updated'))
                    this.$emit('update-sort')
                })
            } else {
                this.createQuestions(this.createRequestPayload()).then(response => {
                    this.payload = camelCaseKeys(response)
                    this.$toasted.success(this.$t('success_created'))
                    this.$emit('update-sort')
                })
            }
        },
        saveAllQuestions(){
            let elements = document.querySelectorAll('.jq-test-save');
            
            for (let elem of elements) {
                let e = document.createEvent('HTMLEvents');
                
                e.initEvent('click', false, true);
                elem.dispatchEvent(e)
            }
        }
    }
}
