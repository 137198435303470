import { mapActions, mapGetters, mapMutations } from 'vuex'
import { camelCase, debounce, snakeCase } from 'lodash'

import mixins from '@/mixins'
import validationMixin from '@/mixins/validation'

import ImageFile from '@/components/image-file/index.vue'
import Draggable from 'vuedraggable'

import SelectPackages from '@/components/forms/SelectPackages'
import { courseContentStudyingLessonContentTableHead } from '@/modules/courses/helpers/content-styding-lessons'

export default {
  name: 'courses-contents-studying-lesson-content',
  mixin: [mixins, validationMixin],
  data() {
    return {
      showRemoveDialog: false,
      removingItem: null,
      showContentDialog: false,
      selectedItem: null,
      valid: true,
      contents: [],
      payload: {
        typeId: '',
        name: '',
        statusId: '',
        type: '',
        sort: 1,
        packages: '',
        link: '',
        src: '',
        image: {
          data: {}
        }
      }
    }
  },
  components: {
    ImageFile,
    Draggable,
    SelectPackages
  },
  watch: {
    'list'() {
      this.contents = this.list
    }
  },
  computed: {
    ...mapGetters({
      editId: 'homeWorkContent/editId',
      setting: 'courses/setting',
      packagesList: 'coursesPackages/list',
      currentItem: 'homeWork/currentItem',
      list: 'homeWorkContent/list',
      loading: 'homeWorkContent/loading',
      listLoading: 'homeWorkContent/listLoading',
    }),
    courseContentStudyingLessonContentTableHead
  },
  created() {
    // if (this.list.length <= 0) {
      // eslint-disable-next-line camelcase
      const { course_id, module_id } = this.$route.params
      this.fetchList({
        course_id: course_id,
        module_id: module_id,
        homeWork_id: this.currentItem.id
      }).then(() => {
        this.contents = this.list
      })
    // } else {
    //   this.contents = this.list
    // }
  },
  mounted() {
    setInterval(() => this.createRequestPayload(), 3000)
  },
  methods: {
    ...mapActions({
      fetchList: 'homeWorkContent/GET_LIST',
      create: 'homeWorkContent/CREATE',
      remove: 'homeWorkContent/DELETE',
      update: 'homeWorkContent/UPDATE',
      updateSort: 'homeWorkContent/UPDATE_SORT',
    }),
    ...mapMutations({}),
    updateImageLocal(params) {
      this.payload.src = params.get('src')
    },

    // remove
    removeListItem() {
      this.remove(this.removingItem).then(() => {
        this.showRemoveDialog = false
        this.$toasted.success(this.$t('success_deleted'))
      })
    },
    changeDialogDeleteItem(item) {
      this.showRemoveDialog = true
      // eslint-disable-next-line camelcase
      const { course_id, module_id } = this.$route.params
      this.removingItem = {
        course_id: course_id,
        module_id: module_id,
        homeWork_id: this.currentItem.id,
        id: item.id
      }
    },
    // actions data
    setData(item) {
      const transformObj = {}
      Object.keys(item).forEach(e => {
        transformObj[camelCase(e)] = item[e]
      })
      Object.assign(this.payload, transformObj)
      this.payload.packages = item.packages.data.map(e => e.id)
    },

    // popup change content
    resetPayload() {
      this.selectedItem = null
      Object.keys(this.payload).forEach(e => {
        this.payload[e] = ''
      })
      this.payload.sort = 1
      this.$refs.form.reset()
    },
    changeShowDialogContent(item = null, status = false, type = 'file') {
      this.payload.type = type
      if (item) {
        this.setData(item)
        this.payload.type = type
        this.selectedItem = item
      } else {

      }
      this.showContentDialog = status
      if (!status) {
        this.resetPayload()
      }
    },
    createRequestPayload() {
      const data = this.payload
      const formData = new FormData()
      Object.keys(data).forEach(e => {
        if (e !== 'typeId') {
          formData.append(snakeCase(e), data[e])
        }
      })
      // eslint-disable-next-line camelcase
      const { course_id, module_id } = this.$route.params
      formData.append('course_id', course_id)
      formData.append('module_id', module_id)
      formData.append('homeWork_id', this.currentItem.id)
      // if add new type you need take setting.content.types
      formData.append('type_id', this.payload.type === 'video' ? 1 : 2)

      this.selectedItem && formData.append('_method', 'patch')
      return formData
    },
    submit() {
      this.$refs.form.validate()
      if (this.valid) {
        if (!this.selectedItem) {
          this.create(this.createRequestPayload()).then(() => {
            this.$toasted.success(this.$t('success_created'))
            this.changeShowDialogContent()
          })
        } else {
          this.update(this.createRequestPayload()).then(() => {
            this.$toasted.success(this.$t('success_updated'))
            this.changeShowDialogContent()
          })
        }
      }
    },
  
    changeSort: debounce(function (e) {
      if (e.hasOwnProperty('moved')) {
        const sortsList = this.contents.map(e => e.id)
        // eslint-disable-next-line camelcase
        const { course_id, module_id } = this.$route.params
        const formData = {
          course_id: course_id,
          module_id: module_id,
          homeWork_id: this.selectedItem.id,
          contents: sortsList
        }
        this.updateSort(formData).then(() => {
          this.$toasted.success(this.$t('success_change_position'))
        })
      }
    }, 2000)
  }
}
