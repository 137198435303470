import { mapActions, mapGetters, mapMutations } from 'vuex'

import SeoForm from '@/components/seo-form/index.vue'

export default {
  name: 'courses-contents-studying-lesson-seo',
  computed: {
    ...mapGetters({
      seo: 'lessonsSeo/seo',
      seoLoading: 'lessonsSeo/seoLoading',
      updateSeoLoading: 'lessonsSeo/updateSeoLoading',
      currentItem: 'lessons/currentItem'
    })
  },
  components: {
    SeoForm
  },
  created () {
    if (!this.seo) {
      // eslint-disable-next-line camelcase
      const { course_id, module_id } = this.$route.params
      this.fetchData({
        course_id: course_id,
        module_id: module_id,
        lesson_id: this.currentItem.id
      })
    }

	this.$on('event-save', () =>{
	  this.$refs['seoForm'].$emit('event-submit');
	});
  },
  methods: {
    ...mapActions({
      fetchData: 'lessonsSeo/GET_SEO',
      update: 'lessonsSeo/UPDATE_SEO'
    }),

    ...mapMutations({
      setSeo: 'lessonsSeo/SET_SEO'
    }),

    submit (formData) {
      // eslint-disable-next-line camelcase
      const { course_id, module_id } = this.$route.params
      formData.course_id = course_id
      formData.module_id = module_id
      formData.lesson_id = this.currentItem.id

      this.update(formData).then(() => {
        this.$toasted.success(this.$t('success_updated'));
      })
    }
  },
  destroyed () {
    this.setSeo(null)
  }
}
