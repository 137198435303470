import { mapGetters, mapMutations } from 'vuex'

import General from '../general/index.vue'
import HWContent from '../content/index.vue'
import Questions from '../questions/index.vue'

export default {
  name: 'courses-contents-studying-home-work-dialog-view',
  data() {
    return {
      dialog: true,
      activeTab: 'general',
    }
  },
  components: {
    General,
    HWContent,
    Questions
  },
  computed: {
    ...mapGetters({
      currentItem: 'homeWork/currentItem',
      isLoadingHomeWork: 'homeWork/isLoading',
      isLoadingQuestion: 'homeWorkQuestions/isListLoading',
      showHomeWorkDialog: 'homeWork/showHomeWorkDialog',
      isLoading: 'homeWork/isLoading',
      practicType: 'homeWork/practicType',
    }),
    tabs() {
      return [
        {
          name: 'General',
          slug: 'general',
          id: 1
        },
        {
          name: 'Content',
          slug: 'content',
          id: 2,
          disabled: !this.currentItem
        },
        {
          name: 'Questions',
          slug: 'questions',
          id: 3,
          disabled: !this.currentItem
        }
      ]
    }
  },
  methods: {
    ...mapMutations({
      changeCloseDialog: 'homeWork/CHANGE_SHOW_HOME_WORK_DIALOG_LESSON',
      setHomeWorkItem: 'homeWork/SET_ITEM'
    }),

    save() {
      this.$refs['general'].$emit('event-save');
    },

    saveAllQuestions() {
      this.$refs['general'].$emit('event-save-all-question');
    }
  },
  destroyed() {
    this.setHomeWorkItem(null)
  }
}
