import Vue from 'vue'
import ItemCheckbox from './item/index.vue'

export default {
    name        : 'form-checkbox',
    props       : {
        payload: {
            type: Object
        },
        items: {
            type: Array
        }
    },
    components  : {
        ItemCheckbox
    },
    data () {
        return {
            list            : [],
            renderComponent : true,
            sort            : 0
        }
    },
    created () {
        this.list   = this.items;
        this.sort   = this.payload.sort == 0 ? 1 : this.payload.sort;
        this.list.length || this.addItem()
    },
    methods     : {
        removeItem (item) {
            if (!item.disabled) {
                const index = this.list.findIndex(e => e.id === item.id);
                
                this.list.splice(index, 1)
            } else {
                this.isHasAnother = false
            }
            
            this.renderComponent = false
            
            this.$nextTick(() => {
                this.renderComponent = true
            })
        },
        updateItem (item) {
            const index = this.list.findIndex(e => e.id === item.id);
            
            Vue.set(this.list, index, item)
        },
        addItem () {
            const item = {
                id      : new Date().getTime(),
                text    : '',
                correct : false
            };
            
            this.list.push(item)
        }
    }
}
