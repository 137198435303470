import Vue from 'vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { debounce } from 'lodash'

import mixins from '@/mixins'
import validationMixin from '@/mixins/validation'

import Draggable from 'vuedraggable'
import Item from '../../components/item/index.vue'

export default {
    name    : 'courses-contents-studying-home-work-content',
    mixin   : [mixins, validationMixin],
    display : 'Simple',
    order   : 0,
    data () {
        return {
            valid           : false,
            draggableList   : [],
            showRemoveDialog: false,
            deletedItem     : null,
            updateList      : true
        }
    },
    components: {
        Draggable,
        Item
    },
    watch: {
        'list' () {
            this.draggableList = this.list
        }
    },
    computed: {
        ...mapGetters({
            currentItem     : 'homeWork/currentItem',
            list            : 'homeWorkQuestions/list',
            isListLoading   : 'homeWorkQuestions/isListLoading',
            isLoading       : 'homeWorkQuestions/isLoading'
        }),
        hasPracticType(){
            if(!this.currentItem) return false;
            
            return this.currentItem.typeId === 2;
        }
    },
    created () {
        this.fetchQuestions({
            ...this.$route.params,
            id: this.currentItem.id
        })
        .then(() => {
            if (this.list.length) {
                this.draggableList = this.list
            } else {
                this.addQuestion()
            }
        });
        
        this.$on('event-save-all-question', () => {
            let elements = document.querySelectorAll('.jq-test-save');
            
            for (let elem of elements) {
                let e = document.createEvent('HTMLEvents');
                
                e.initEvent('click', false, true);
                elem.dispatchEvent(e)
            }
        });
    },
    methods: {
        ...mapActions({
            fetchQuestions  : 'homeWorkQuestions/GET_LIST',
            removeQuestion  : 'homeWorkQuestions/DELETE',
            update          : 'homeWorkQuestions/UPDATE',
            updateSort      : 'homeWorkQuestions/UPDATE_SORT'
        }),
        ...mapMutations({}),
        showRemovePopup (item) {
            this.deletedItem = item
            this.showRemoveDialog = true
        },
        updateSortQuestions () {
            console.log('questions updateSortQuestions:');
            
            this.fetchQuestions({
                ...this.$route.params,
                id: this.currentItem.id
            })
            .then(() => {
                this.draggableList  = this.list;
                this.updateList     = false;
                
                this.$nextTick(() => {
                    this.updateList = true;
                })
            })
        },
        removeListItem () {
            if (this.deletedItem.hasOwnProperty('updatedAt')) {
                this.removeQuestion({
                    ...this.$route.params,
                    home_work_id: this.currentItem.id,
                    id          : this.deletedItem.id
                })
                .then(response => {
                    const index = this.draggableList.findIndex(e => e.id === this.deletedItem.id);
                    
                    this.draggableList.splice(index, 1);
                    
                    this.showRemoveDialog = false;
                    this.draggableList = this.list;
                    this.updateList = false;
                    
                    this.$nextTick(() => {
                        this.updateList = true
                    })
                }).catch(() => {})
            } else {
                const index = this.draggableList.findIndex(e => e.id === this.deletedItem.id);
                
                this.draggableList.splice(index, 1);
                this.showRemoveDialog = false;
            }
        },
        addQuestion () {
            this.draggableList.push({
                id      : new Date().getTime(),
                text    : '',
                typeId  : this.currentItem.typeId,
                sort    : this.draggableList.length + 1,
                answers : { data: [] }
            })
        },
        updateItem (item) {
            const index = this.draggableList.findIndex(e => e.id === item.id);
            
            Vue.set(this.draggableList, index, item)
        },
        changeSortQuestion: debounce(function (e) {
            if (e.hasOwnProperty('moved')) {
                const sortsList = this.draggableList.map(e => e.id);
                const formData  = new FormData();
                
                this.updateList = false;
                
                formData.append('course_id'     , this.$route.params.course_id);
                formData.append('module_id'     , this.$route.params.module_id);
                formData.append('home_work_id'  , this.currentItem.id);
                formData.append('questions'     , sortsList.join(','));
                formData.append('_method'       , 'patch');
                
                this.updateSort(formData).then(() => {
                    this.$toasted.success(this.$t('success_change_position'));
                    
                    this.draggableList = this.list;
                    
                    for(let i = 0; i < this.list.length; i++){
                        Vue.set(this.draggableList, i, this.list[i]);
                    }
                    
                    this.$nextTick(() => {
                        this.updateList = true;
                    });
                })
            }else{
                this.updateList = false;
                
                this.$nextTick(() => {
                    this.updateList = true;
                });
            }
        }, 1)
    }
}
