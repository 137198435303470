export function courseContentStudyingLessonContentTableHead () {
  return [
    {
      text: 'Name',
      value: 'name'
    },
    {
      text: 'Type',
      value: 'type'
    },
    {
      text: 'Packages',
      value: 'packages'
    },
    {
      text: 'Status',
      value: 'status'
    },
    {
      text: 'Actions',
      value: 'actions',
      sortable: false
    }
  ]
}

export function courseContentStudyingLessonTableHead () {
  return [
    {
        text: 'Sorted',
        value: 'sorted'
    },
    {
      text: 'Name',
      value: 'name'
    },
    {
      text: 'Picture',
      value: 'picture'
    },
    {
      text: 'Opening at',
      value: 'opening_at'
    },
    {
      text: 'Type',
      value: 'type'
    },

    {
      text: 'Status',
      value: 'status'
    },
    {
      text: 'Actions',
      value: 'actions',
      sortable: false
    }
  ]
}
